import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'

import Redwoods from '../../content/assets/redwoods.png'
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainHeader from "../components/header-components/mainHeader"
import { colors } from "../components/colors/colors"

const FourOh = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${Redwoods});
  background-size: cover;
  background-repeat: no-repeat;
`
const TextHolder = styled.article`
  width: 40vw;
  margin: 20px auto;
  color: ${colors.white};
  padding: 50px;
`

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <FourOh>
          <MainHeader/>
          <TextHolder>
            <p>
              Whoops... Well the page you are looking for isn't here.
            </p>
          </TextHolder>
        </FourOh>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
